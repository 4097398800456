<template>
  <div class="index">
    <div class="index-c">
      <div class="container">
        <div v-if="topBanner.length && !isMobile" class="menu">
          <el-carousel ref="carousel" indicator-position="outside">
            <el-carousel-item v-for="item in topBanner" :key="item.id">
              <VideoImage class="image-wrapper" @click="openBanner(item)" :src="item.imgUrl" v-if="item.imgUrl" />
            </el-carousel-item>
          </el-carousel>
        </div>
        <div v-if="topBanner.length && isMobile" class="swiper mySwiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in topBanner" :key="index">
              <VideoImage isMobile class="image-wrapper" @click="openBanner(item)" :src="item.imgUrl" />
            </div>
          </div>
        </div>
        <div class="v-rec-wrapper">
          <ItemTitle :more="false" title="最新精品" />
          <div class="flex-right-hand">
            <el-row ref="scrollArea"  :gutter="18">
              <el-col v-for="(v, index) in goodRecommendList.slice(0, 6)" class="v-rec-item" :span="4" :key="index">
                <NewVideoItem class="new-rec" :width="300" :video="v" />
              </el-col>
            </el-row>
            <div class="right-hander" @click="goRight" v-if="isMobile">
              <i class="el-icon-arrow-right"></i>
            </div>
          </div>
        </div>
        <div class="video-content">
          <div v-for="(i, index) in Math.ceil(categoryRecList.length / 2)" class="info-item" :key="i">
            <div class="body">
              <section class="left-section">
                <div class="rec-c-wrapper" v-for="(c, ix) in categoryRecList.slice((i - 1) * 2, i * 2)" :key="c.id">
                  <ItemTitle :cid="c.id" :title="c.name" />
                  <div class="rec-c-right">
                    <VideoSwipper :videos="c.medias" :index="ix" :isMobile="isMobile" />
                  </div>
                </div>
              </section>
              <section class="right-section">
                <p class="title-c">
                  {{titles[index]}}
                </p>
                <div class="rec-wrapper">
                  <p class="rec-item" @click="goPopular(r)" v-for="(r, index) in popular[index]" :key="r.id">
                    <span class="index">{{index + 1}}. </span><span class="content">{{r.mediaName}}</span>
                  </p>
                </div>
              </section>
            </div>
            <div v-if="bg[i - 1]" class="bg-wrapper">
              <div v-for="(img, index) in bg[i - 1]" @click="openAdv(img)" :key="index">
                <VideoImage class="img" :src="img.url" />
              </div>
            </div>
          </div>
          <div v-for="c in bg.slice(Math.ceil(categoryRecList.length / 2))" :key="c" class="bg-wrapper">
            <div v-for="(img, index) in c" @click="openAdv(img)" :key="index">
              <VideoImage class="img" :src="img.url" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot name="footer" />
  </div>
</template>

<script>
import { 
  categoryBanner,
  getNewsList as _getNewsList,
  goodRecommend as _goodRecommend,
  advert,
  popularGet as _popularGet,
  categoryRec as _categoryRec
} from "@api"
import NewVideoItem from '@c/NewVideoItem.vue'
// import RecVideoItem from '@c/RecVideoItem.vue'
import VideoImage from "@c/VideoImage.vue";
import VideoSwipper from "@c/VideoSwipper.vue"
import ItemTitle from "@c/ItemTitle.vue"
import { nextTick, reactive, ref, toRefs, watch, } from "vue";
import { useRouter } from 'vue-router'

// const sortList = ['国文教育', '国艺素养', '国医养生', '国史人文', '大师说', '名家直播课']
const titles = ['热搜榜', '名师好课榜', '上新榜']

console.log(window.Swiper, '000')
export default {
  name: "index",
  components: {
    NewVideoItem,
    // RecVideoItem,
    VideoImage,
    VideoSwipper,
    ItemTitle
  },
  props: {
    menus: Array
  },
  setup (props) {
    const dropdown = ref(null)
    const router = useRouter()
    const carousel = ref(null)
    const scrollArea = ref(null)
    // const route = useRoute()
    const isMobile = ref(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/.test(window.navigator.userAgent))
    const state = reactive({
      menuItemShow: false,
      activeItem: isMobile.value && props.menus[0],
      topBanner: [],
      leftBanner: [],
      news: [],
      goodRecommendList: [],
      activeBizType: 0,
      popular: [],
      categoryRecList: [],
      menuIdMap: [],
      bg: []
    })

    const getAdvert = () => {
      advert().then(res => {
        state.bg = Object.values(res.data || {})
      })
    }

    const openItemPage = (parent, current) => {
      console.log('333')
      router.push(`/videos/${state.activeItem.id}/${parent.id}/${current.id}`)
    }
    const getBanners = () => {
      const urls = []
      let originData = []
      return categoryBanner(0).then(res => {
        state.topBanner = []
        originData = res.data.sort((a,b) => a.orderNum - b.orderNum)
        nextTick(() => {
          if (window.innerWidth > 767) {
            state.topBanner = originData.map(((item, index) => {
              urls.push(item.imgUrl)
              if (index !== 0) {
                delete item.imgUrl
              }
              return item
            }))
            setTimeout(() => {
              state.topBanner = originData.map((i, index) => {
                i.imgUrl = urls[index]
                return i
              })
            }, 1500)
          } else {
            state.topBanner = originData
            nextTick(() => {
              new window.Swiper(".mySwiper", {
                autoplay: {
                  delay: 3000,
                  disableOnInteraction: false,
                },
                loop: true
              });
            })
          }
        })
      })
    }
    const getNewsList = () => {
      _getNewsList({
        pageSize: 10,
        currentPage: 0
      }).then(res => {
        state.news = res.data.content
      })
    }

    const goodRecommend = () => {
      _goodRecommend().then(res => {
        state.goodRecommendList = res.data
        const rest = 5 - res.data.length
        if (rest > 0) {
          state.goodRecommendList.push(...new Array(5 - rest).fill(null))
          console.log(state.goodRecommendList)
        }
      })
    }
    const popularGet = () => {
      Promise.all([
        _popularGet({
          bizId: 0
        }),
        _popularGet({
          bizId: 1
        }),
        _popularGet({
          bizId: 2
        })
      ]).then((res) => {
        state.popular = res.map(i => i.data)
      })
    }

    const goPopular = (i) => {
      // if (i.mediaType === 'VIDEO') {
      router.push(`/video/${i.mediaId}`)
      // } else {
      //   router.push(`/audio/${i.mediaId}`)
      // }
    }
    const categoryRec = () => {
      _categoryRec().then(res => {
        state.categoryRecList = res.data?.recVOList || []
      })
    }

    const openAdv = (ad) => {
      if (ad.goToUrl) {
        if (ad.jump) {
          window.open(ad.goToUrl)
        } else {
          window.location.href = ad.goToUrl
        }
      }
    }

    const openBanner = (banner) => {
      if (banner.videoId) {
        router.push('/video/' + banner.videoId)
      } else if (banner.jumpUrl) {
        window.open(banner.jumpUrl)
      }
    }

    getBanners()
    categoryRec()
    getNewsList()
    goodRecommend()
    getAdvert()
    popularGet()
    

    watch(() => props.menus, (v) => {
      if (isMobile.value) {
        state.activeItem = v[0]
      }
    })

    return {
      ...toRefs(state),
      dropdown,
      openItemPage,
      getNewsList,
      goodRecommend,
      goPopular,
      isMobile,
      titles,
      router,
      openAdv,
      openBanner,
      carousel,
      scrollArea,
      goRight() {
        scrollArea.value.$el.scrollLeft = scrollArea.value.$el.scrollLeft + scrollArea.value.$el.offsetWidth
      }
    }
  }
};
</script>


<style scoped lang="stylus">
.container {
  position: relative;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.body {
  display: flex;
  padding-top: 20px;
}

.menu /deep/ .el-carousel__container {
  height: 450px;
}

.left-section {
  flex: 1;
  padding-right: 40px;
  margin-top: 20px;
}

.right-section {
  width: 185px;
}

.video-content {
  margin-top: 50px;
}

.index-c {
  height: 100%;
  position: relative;
  padding-top: 20px;
  box-sizing: border-box;
  margin-bottom 20px
}

.rec-item {
  display: flex;
  font-size: 15px;
  padding: 5px 0;
  color: #361905;
  justify-content: space-between;
  font-family: SourceHanSansCN-Bold;

  & .index {
    display: inline-block;
    height: 25px;
    line-height: 25px;
    width: 25px;
    text-align: center;
  }

  & .content {
    height: 25px;
    line-height: 25px;
    width: 0;
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
}

.rec-item:nth-of-type(1) .index {
  color: #dd0305;
}

.rec-item:nth-of-type(2) .index {
  color: #f38405;
}

.rec-item:nth-of-type(3) .index {
  color: #f7aa24;
}

.title-c {
  font-size: 30px;
  height: 30px;
  line-height: 30px;
  font-family: SourceHanSansCN-Bold;
  color: #361905;
  margin-bottom: 20px;
}

.v-rec-wrapper {
  margin-top: 20px;
}

.good-right {
  width: calc(100% - 300px);

  & .video-container {
    width: 33.3%;
    display: inline-block;
  }
}

.image-wrapper {
  width: 100%;
  height: 100%;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: #f5f7fa;
  color: #909399;
  font-size: 30px;
}

.rec-c-wrapper {
  margin-bottom: 20px;
}

.bg-wrapper .img {
  width: 100%;
  height: 130px;
}

.info-item + .info-item {
  margin-top 35px
}
@media screen and (max-width: 767px) {
  .swiper {
    height: calc((100vw - 20px) * 0.435)
  }
  .v-rec-item {
    width: 210px;
    max-width: 210px;
    flex: auto;
    flex-shrink: 0;
  }
  .v-rec-wrapper .el-row {
    flex-wrap: nowrap;
    overflow auto;
    padding-bottom 20px;
    scroll-behavior: smooth;
  }
  .right-section {
    display none;
  }
  .left-section {
    padding-right 0
  }
  .bg-wrapper .img {
    width: 100%;
    height: 100px;
  }
  .flex-right-hand {
    display flex
    justify-content: space-between;
    .el-row {
      width: calc(100% - 20px)
    }
    .right-hander {
      width 30px
      background: rgba(0,0,0,.3);
      display flex
      align-items center
      justify-content center
      font-size 20px
      color #fff
      font-weight bold
      margin-bottom 20px
    }
  }
}
</style>
